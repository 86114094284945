import React from "react";
import "../styles/style.css";
import Logo from "../assets/Images/correctsizeImage.png";
import { Grid, Button, Divider } from "@mui/material";
import InstagramIcon from "../assets/Images/Instagram.png";
import Linkedin from "../assets/Images/linkedin.png";

const Footer = (props) => {
  return (
    <div className="footer">
      <div className="footerProduct">
        <Grid container>
          <Grid item xs={12} lg={6}>
            <img src={Logo} alt="arrowbutton" className="logoDesign" />
          </Grid>

          <Grid item container xs={12} lg={6}>
            <Grid item xs={12} lg={6} className="buttoncontain">
              <Button
                className="listbutton2"
                onClick={() => props.scrollToSection("productSection")}
              >
                PRODUCT
              </Button>
              <Button
                className="listbutton2"
                onClick={() => props.scrollToSection("serviceSection")}
              >
                SERVICES
              </Button>
              <Button
                className="listbutton2"
                onClick={() => props.scrollToSection("aboutSection")}
              >
                ABOUT
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              container
              justifyContent={"end"}
              className="marginSet"
            >
              <Button
                className="ordernow4"
                onClick={() => props.scrollToSection("TopContainer")}
              >
                BACK TO TOP
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="instaContainer">
          {/* <img src={InstagramIcon} alt="arrowbutton" className="instaIcon" /> */}
          {/* <img src={Linkedin} alt="arrowbutton" className="LinkedinIcon" /> */}
          <a href="mailto:info@toughscreen.co.uk" className="toughscreenInfo">
            info@toughscreen.co.uk 
          </a>
        </Grid>
        <Divider className="divider_std_footer" />
        <p className="endtext">
          Copyright &copy; 2025 Toughscreen Ltd. All rights reserved. | Registered in
          England: 15258935 | Registered address: 124-128 City Road, London,
          EC1V 2NX
        </p>
      </div>
    </div>
  );
};

export default Footer;
